import axios from "axios";

const http = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BACKEND_API,
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
